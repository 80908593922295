<template>

  <div class="signup rel-container">

    <!-- Drawer Nav Trigger -->
    <div class="pos-abs">
      <v-btn dark icon @click="drawer=!drawer">
      <v-icon>menu</v-icon>
      </v-btn>
    </div>

    <!-- Drawer Nav -->
    <nav-drawer
      v-model="drawer"
    ></nav-drawer>

    <v-container fill-height>
    <v-layout fill-height align-center justify-center>

      <div>
        <div class="text-xs-center white--text headline">
          Sign up
        </div>
        <firebase-u-i :callback="callback"></firebase-u-i>
        <div class="text-xs-center white--text mt-2">
          <span class="caption">Thank you for signing up!</span>
        </div>
        <div class="text-xs-center white--text mt-1">
          <img class="mr-1" style="vertical-align:middle; height:20px;" src="/images/logo.svg" alt="" />
          <span class="logo">Swipewrap</span>
        </div>
      </div>

    </v-layout>
    </v-container>
  </div>

</template>


<script>
import NavDrawer from '@/components/navs/NavDrawer.vue'
import FirebaseUI from '@/components/auth/FirebaseUI.vue'

export default {
  name:'signup',
  components: {
    NavDrawer,
    FirebaseUI,
  },
  data() {
    return {
      doneButtonProcessing:false,
      drawer:false,
    }
  },
  computed: {
    email() {
      if( this.$store.getters.user ) { return this.$store.getters.user.email; }
      else{ return null; }
    },
  },
  methods: {

    callback(user) {
      if( user.emailVerified ) {
        this.$router.push('/');
      }
    },

  }
}
</script>

<style scoped>

.signup {
  background-color: #B03060;
}

</style>
